import * as types from "../Constants/actionTypes";

// |User Actions
export const setUser = (user) => ({
  type: types.SET_USER,
  payload: user,
});

export const updateUser = (user) => ({
  type: types.UPDATE_USER,
  payload: user,
});

export const addTitle = (title) => ({
  type: types.ADD_TITLE,
  payload: title,
});

export const clearUser = () => ({
  type: types.CLEAR_USER,
});

export const setIsAdmin = (admin) => ({
  type: types.SET_ADMIN,
  payload: admin,
});

export const setClientID = (clientId) => ({
  type: types.SET_ACTIVE_CLIENT,
  payload: clientId,
});

export const setProjectID = (projectId) => ({
  type: types.SET_ACTIVE_PROJECT,
  payload: projectId,
});

export const setArchived = (hideArchived) => ({
  type: types.SET_ARCHIVED,
  payload: hideArchived,
});

export const addArchivedProject = (project) => ({
  type: types.ADD_ARCHIVED_PROJECT,
  payload: project,
});

export const removeArchivedProject = (projectId) => ({
  type: types.REMOVE_ARCHIVED_PROJECT,
  payload: projectId,
});

export const setCompanyCode = (companyCode) => ({
  type: types.SET_COMPANY_CODE,
  payload: companyCode,
});

export const clearCompanyCode = () => ({
  type: types.CLEAR_COMPANY_CODE,
});

// |Client Actions
export const setClient = (client) => ({
  type: types.SET_CLIENT,
  payload: {
    id: client.id,
    name: client.name,
    shortName: client.shortName,
    address: client.address,
    city: client.city,
    zip: client.zipCode,
    phone: client.phone,
  },
});

// -No payload needed
export const clearClient = () => ({
  type: types.CLEAR_CLIENT,
});
