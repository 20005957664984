// User Actions
export const SET_USER = "SET_USER";
export const UPDATE_USER = "UPDATE_USER";
export const ADD_TITLE = "ADD_TITLE";
export const CLEAR_USER = "CLEAR_USER";
export const SET_ADMIN = "SET_ADMIN";
export const SET_ACTIVE_CLIENT = "SET_ACTIVE_CLIENT";
export const SET_ACTIVE_PROJECT = "SET_ACTIVE_PROJECT";
export const SET_ARCHIVED = "SET_ARCHIVED";
export const SET_COMPANY_CODE = "SET_COMPANY_CODE";
export const CLEAR_COMPANY_CODE = "CLEAR_COMPANY_CODE";
export const ADD_ARCHIVED_PROJECT = "ADD_ARCHIVED_PROJECT";
export const REMOVE_ARCHIVED_PROJECT = "REMOVE_ARCHIVED_PROJECT";

// Client Actions
export const SET_CLIENT = "SET_CLIENT";
export const CLEAR_CLIENT = "CLEAR_CLIENT";
