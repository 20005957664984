import axios from "axios";
import {
  clearUser,
  setClient,
  setClientID,
  setCompanyCode,
  setIsAdmin,
  setProjectID,
  setUser,
} from "../../Redux/Actions/actions.js";
import { fetchClientRows } from "../Dashboard/Client/ClientQueries/useClientRows.jsx";
import { fetchCurrentProject } from "../Dashboard/DashboardComponents/Form/FormQueries/useCurrentProject.jsx";
import { fetchSlideState } from "../Dashboard/DashboardComponents/Form/Slides/Hooks/useProjectSlide.jsx";

// Function to verify the token
export const verifyToken = async (token) => {
  console.log("VERIFY TOKEN TOKEN: ", token);
  try {
    let encodedToken = encodeURIComponent(token);
    console.log("ENCODED TOKEN: ", encodedToken);
    const res = await axios.get(`/api/verify-token/?token=${encodedToken}`, {
      withCredentials: true,
    });

    return { status: res.status, data: res.data };
  } catch (err) {
    console.log("VERIFY ERR: ", err);
  }
};

// Function to fetch the token
export const fetchToken = async (token, navigate) => {
  const verify = await verifyToken(token);

  if (verify.status === 200) {
    return verify.data;
  } else {
    navigate("/login", { replace: true });
  }
};

export const validAdminUser = async (
  tokenData,
  setOpenModal,
  dispatch,
  navigate,
) => {
  setOpenModal(() => false);
  const clientId = tokenData.currentClient;
  const projectId = tokenData.currentProject;
  dispatch(setIsAdmin(true));
  dispatch(setUser(tokenData.userData));
  dispatch(setCompanyCode(tokenData.companyCode));
  dispatch(setClientID(clientId));
  dispatch(setProjectID(projectId));

  navigate(`/dashboard/admin/`, { replace: true });
};

export const validClientUser = async (
  tokenData,
  openModal,
  setOpenModal,
  dispatch,
  navigate,
  queryClient,
) => {
  const clientData = tokenData.clientData;
  const userData = tokenData.userData;
  const currentProject = tokenData.currentProject || null;
  const locationName = tokenData.location;
  const companyCode = clientData.companyCode;

  dispatch(setIsAdmin(false));
  dispatch(
    setClient({
      id: clientData.id,
      name: clientData.name,
      address: clientData.address,
      city: clientData.city,
      zipCode: clientData.zip,
      phone: clientData.phone,
    }),
  );
  if (userData && userData.id !== "") {
    dispatch(
      setUser({
        id: userData.id,
        companyCode: companyCode,
        name: userData.name,
        email: userData.email,
        phone: userData.phone,
        title: userData.title,
        admin: false,
        currentClient: clientData.id,
        currentProject: currentProject,
        archivedProjects: userData.archivedProjects || [],
        hideArchived: false,
      }),
    );
    setOpenModal(false);
  } else {
    dispatch(clearUser());
  }

  if (locationName === "dashboard") {
    await queryClient.prefetchQuery({
      queryKey: ["clientRows", clientData.id],
      queryFn: () => fetchClientRows(clientData.id, openModal),
    });
    dispatch(setProjectID(null));
    navigate(`/dashboard/client/${clientData.id}`, { replace: true });
  } else if (locationName === "form") {
    const form = await queryClient.ensureQueryData({
      queryKey: ["currentProject", clientData.id, currentProject],
      queryFn: () => fetchCurrentProject(clientData.id, currentProject),
    });

    if (form) {
      await queryClient.prefetchQuery({
        queryKey: [
          "slideState",
          clientData.id,
          currentProject,
          form.recentSlide,
        ],
        queryFn: () =>
          fetchSlideState(clientData.id, currentProject, form.recentSlide),
      });
      navigate(
        `/dashboard/form/${clientData.id}/${currentProject}/${form.recentSlide}`,
      );
    }
  } else if (locationName === "files") {
    await queryClient.prefetchQuery({
      queryKey: ["file-structure", "project", currentProject, "all"],
      queryFn: async () => {
        const res = await axios.get(
          `/api/file-structure/project/${currentProject}/all/`,
          {
            withCredentials: true,
          },
        );
        return res.data;
      },
    });
    navigate(`/dashboard/files/${clientData.id}/${currentProject}`, {
      replace: true,
    });
  } else if (locationName === "required-files") {
    await queryClient.prefetchQuery({
      queryKey: ["file-structure", "project", currentProject, "required"],
      queryFn: async () => {
        const res = await axios.get(
          `/api/file-structure/project/${currentProject}/required/`,
          {
            withCredentials: true,
          },
        );
        return res.data;
      },
    });
    navigate(`/dashboard/files/${clientData.id}/${currentProject}/required`, {
      replace: true,
    });
  } else if (locationName === "revisions") {
    await queryClient.ensureQueryData({
      queryKey: ["currentProject", clientData.id, currentProject],
      queryFn: () => fetchCurrentProject(clientData.id, currentProject),
    });
    navigate(`/dashboard/revisions/${clientData.id}/${currentProject}`, {
      replace: true,
    });
  } else if (locationName === "completed") {
    await queryClient.ensureQueryData({
      queryKey: ["clientRows", clientData.id],
      queryFn: () => fetchClientRows(clientData.id, false),
    });
    navigate(`/dashboard/client/${clientData.id}`, { replace: true });
  }
};
