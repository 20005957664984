const initialState = {
  id: null,
  name: "",
  shortName: "",
  address: "",
  city: "",
  zip: "",
  phone: "",
};

// Client Information Reducer
const clientReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_CLIENT":
      return {
        ...state,
        ...action.payload,
      };
    case "CLEAR_CLIENT":
      return {
        ...state,
        id: null,
        name: "",
        shortName: "",
        address: "",
        city: "",
        zip: "",
        phone: "",
      };
    default:
      return state;
  }
};

export default clientReducer;
