const initialState = {
  id: null,
  companyCode: "",
  name: "",
  email: "",
  phone: "",
  title: "",
  admin: false,
  currentClient: null,
  currentProject: null,
  archivedProjects: [],
  hideArchived: false,
};

// Current User Reducer
export const userReducer = (state = initialState, action) => {
  if (action.type === "SET_COMPANY_CODE") {
    console.log("PAYLOAD: ", action.payload);
  }
  switch (action.type) {
    case "SET_USER":
      return {
        ...action.payload,
      };
    case "UPDATE_USER":
      return {
        ...state,
        ...action.payload,
      };
    case "ADD_ARCHIVED_PROJECT":
      return {
        ...state,
        archivedProjects: [...state.archivedProjects, action.payload],
      };
    case "REMOVE_ARCHIVED_PROJECT":
      return {
        ...state,
        archivedProjects: state.archivedProjects.filter(
          (project) => project.id !== action.payload,
        ),
      };

    case "ADD_TITLE":
      return {
        ...state,
        title: action.payload,
      };
    case "CLEAR_USER":
      return {
        ...state,
        id: null,
        name: "",
        email: "",
        phone: "",
        title: "",
        admin: false,
        currentClient: null,
        currentProject: null,
        hideArchived: false,
        companyCode: null,
      };
    case "SET_ADMIN":
      return {
        ...state,
        admin: action.payload,
      };
    case "SET_ACTIVE_CLIENT":
      return {
        ...state,
        currentClient: action.payload,
      };
    case "SET_ACTIVE_PROJECT":
      return {
        ...state,
        currentProject: action.payload,
      };
    case "SET_ARCHIVED":
      return {
        ...state,
        hideArchived: action.payload,
      };
    case "SET_COMPANY_CODE":
      return {
        ...state,
        companyCode: action.payload,
      };
    case "CLEAR_COMPANY_CODE":
      return {
        ...state,
        companyCode: "",
      };
    default:
      return state;
  }
};
