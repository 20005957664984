import React from "react";
import "./styles/navbarlinks.css";

// Navbar Links Component when screen size is smaller
const NavbarLinks = () => {
  return (
    <div id="nav-list-container">
      <div className="list-box-item">
        <a href="https://arguscs.com/phoenix/" target="_blank" rel="noreferrer">
          Home
        </a>
      </div>
      <div className="list-box-item">
        <a
          href="https://https://arguscs.com/phoenix/about/"
          target="_blank"
          rel="noreferrer"
        >
          About
        </a>
      </div>
      <div className="list-box-item">
        <a
          href="https://arguscs.com/phoenix/who-we-are/"
          target="_blank"
          rel="noreferrer"
        >
          Who We Are
        </a>
      </div>
      <div className="list-box-item">
        <a
          href="https://arguscs.com/phoenix/services"
          target="_blank"
          rel="noreferrer"
        >
          Services
        </a>
      </div>
      <div className="list-box-item">
        <a
          href="https://arguscs.com/phoenix/markets"
          target="_blank"
          rel="noreferrer"
        >
          Markets
        </a>
      </div>
      <div className="list-box-item">
        <a
          href="https://arguscs.com/phoenix/who-we-serve/"
          target="_blank"
          rel="noreferrer"
        >
          Who We Serve
        </a>
      </div>
      <div className="list-box-item">
        <a
          href="https://arguscs.com/phoenix/faq"
          target="_blank"
          rel="noreferrer"
        >
          FAQ
        </a>
      </div>
      <div className="list-box-item">
        <a
          href="https://arguscs.com/phoenix/careers"
          target="_blank"
          rel="noreferrer"
        >
          Careers
        </a>
      </div>
      <div className="list-box-item">
        <a
          href="https://arguscs.com/phoenix/contact"
          target="_blank"
          rel="noreferrer"
        >
          Contact
        </a>
      </div>
    </div>
  );
};

export default NavbarLinks;
