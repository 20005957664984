import React, { useState } from "react";
import { List, XLg } from "react-bootstrap-icons";
import logo from "../../assets/full-argus-logo.png";
import NavbarLinks from "./NavbarLinks";
import "./styles/navbar.css";

const Navbar = () => {
  // stateful variables to handle the navbar
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const [listSize, setListSize] = useState(35);
  const [showModal, setShowModal] = useState(false);

  //Attach the event handler function to the window object
  window.addEventListener("resize", () => {
    setInnerWidth(window.innerWidth);
  });

  //An event handler function that shows the modal when the user clicks on the hamburger icon
  const handleShowModal = (e) => {
    if (e.target.id === "hamburger") {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  };

  return (
    // Navbar container
    <div className="navbar-container">
      {/* Inner Navbar container */}
      <div className="navbar-inner">
        {/* Logo container */}
        <div id="logo-container">
          <a
            href="https://arguscs.com/phoenix/"
            target="_blank"
            rel="noreferrer"
          >
            {/* NOT LOADING PNG */}
            <img src={logo} alt="Argus Logo" className="logo" />
          </a>
          <h3 id="division-name">Arizona Division</h3>
        </div>
        {/* Navbar unordered list of links container */}
        <div
          className="nav-links-container"
          id={window.innerWidth < 1620 ? "hide-links" : ""}
        >
          {/* Display the links if the windows width is equal to or above 1300 */}
          {/* Unordered list */}
          {window.innerWidth >= 1620 ? (
            <ul id="nav-links-ul">
              <li className="nav-links-li">
                <a
                  href="https://arguscs.com/phoenix/"
                  className="nav-links-link"
                  target="_blank"
                  rel="noreferrer"
                >
                  Home
                </a>
              </li>
              <li className="nav-links-li">
                <a
                  href="https://arguscs.com/phoenix/about/"
                  className="nav-links-link"
                  target="_blank"
                  rel="noreferrer"
                >
                  About
                </a>
              </li>
              <li className="nav-links-li">
                <a
                  href="https://arguscs.com/phoenix/who-we-are/"
                  className="nav-links-link"
                  target="_blank"
                  rel="noreferrer"
                >
                  Who We Are
                </a>
              </li>
              <li className="nav-links-li">
                <a
                  href="https://arguscs.com/phoenix/services/"
                  className="nav-links-link"
                  target="_blank"
                  rel="noreferrer"
                >
                  Services
                </a>
              </li>
              <li className="nav-links-li">
                <a
                  href="https://arguscs.com/phoenix/markets/"
                  className="nav-links-link"
                  target="_blank"
                  rel="noreferrer"
                >
                  Markets
                </a>
              </li>
              <li className="nav-links-li">
                <a
                  href="https://arguscs.com/phoenix/who-we-serve/"
                  className="nav-links-link"
                  target="_blank"
                  rel="noreferrer"
                >
                  Who We Serve
                </a>
              </li>
              <li className="nav-links-li">
                <a
                  href="https://arguscs.com/phoenix/faq/"
                  className="nav-links-link"
                  target="_blank"
                  rel="noreferrer"
                >
                  FAQ
                </a>
              </li>
              <li className="nav-links-li">
                <a
                  href="https://arguscs.com/phoenix/careers/"
                  className="nav-links-link"
                  target="_blank"
                  rel="noreferrer"
                >
                  Careers
                </a>
              </li>
              <li className="nav-links-li">
                <a
                  href="https://arguscs.com/phoenix/contact/"
                  className="nav-links-link"
                  target="_blank"
                  rel="noreferrer"
                >
                  Contact
                </a>
              </li>
              <li className="nav-links-li active-listItem">
                <a href="/" className="nav-links-link">
                  Project Client Portal
                </a>
              </li>
            </ul>
          ) : window.innerWidth < 1620 && showModal === true ? (
            <>
              <XLg
                id="x-btn"
                size={30}
                color={"white"}
                onClick={handleShowModal}
              />
              <NavbarLinks />
            </>
          ) : window.innerWidth < 1620 ? (
            // Display the hamburger icon if the windows width is below 1300
            <List
              id="hamburger"
              size={listSize}
              color={"white"}
              onClick={handleShowModal}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
