import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import {
  clearClient,
  setClientID,
  setProjectID,
} from "../../Redux/Actions/actions.js";
import { verifySubmission } from "../Dashboard/DashboardComponents/Form/Helper/formbtnhelper.js";
import "./styles/render-snackbar.css";

// Component to render basic snackbar alerts
export const RenderSnackbar = ({
  open,
  setOpen,
  severity,
  message,
  autoHide,
  anchor,
  type,
  stateValues = {},
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // Object containing the different types of snackbar alerts
  const snackbarTypes = {
    baseSnackbar: (
      <Snackbar
        open={open}
        autoHideDuration={autoHide}
        onClose={() => {
          setOpen(false);
        }}
        message={message}
        anchorOrigin={anchor}
        id={`base-${severity}-snackbar`}
        classes={{
          root: "base-snackbar",
        }}
      >
        <Alert
          onClose={() => {
            setOpen(false);
          }}
          severity={severity}
          sx={{ width: "100%" }}
          variant="outlined"
          closeText="Close"
          classes={{
            root: "base-alert",
            outlinedError: "base-error-alert",
            outlinedInfo: "base-info-alert",
            outlinedSuccess: "base-success-alert",
            outlinedWarning: "base-warning-alert",
            icon: "base-alert-icon",
            message: "base-alert-message",
            action: "base-alert-action",
          }}
          slotProps={{
            closeButton: { className: "base-alert-close-btn" },
            closeIcon: { className: "base-alert-close-icon" },
          }}
        >
          {message}
        </Alert>
      </Snackbar>
    ),
    baseSubmitSnackbar: (
      <Snackbar
        open={open}
        autoHideDuration={autoHide}
        onClose={() => {
          setOpen(false);
          if (severity === "success") {
            if (stateValues.isAdmin) {
              navigate("/dashboard/admin");
            } else {
              navigate(`/dashboard/client/${stateValues.clientId}`);
            }
          }
        }}
        message={message}
        anchorOrigin={anchor}
        id={`base-${severity}-snackbar`}
        classes={{
          root: "base-snackbar",
        }}
      >
        <Alert
          onClose={() => {
            setOpen(false);
            if (severity === "success") {
              if (stateValues.isAdmin) {
                navigate("/dashboard/admin");
              } else {
                navigate(`/dashboard/client/${stateValues.clientId}`);
              }
            }
          }}
          severity={severity}
          sx={{ width: "100%" }}
          variant="outlined"
          closeText="Close"
          classes={{
            root: "base-alert",
            outlinedError: "base-error-alert",
            outlinedInfo: "base-info-alert",
            outlinedSuccess: "base-success-alert",
            outlinedWarning: "base-warning-alert",
            icon: "base-alert-icon",
            message: "base-alert-message",
            action: "base-alert-action",
          }}
          slotProps={{
            closeButton: { className: "base-alert-close-btn" },
            closeIcon: { className: "base-alert-close-icon" },
          }}
        >
          {message}
        </Alert>
      </Snackbar>
    ),
    validateQuestionnaireSnackbar: (
      <Snackbar
        open={open}
        autoHideDuration={autoHide}
        onClose={() => {
          setOpen(false);
          if (severity === "success") {
            if (stateValues.isAdmin) {
              navigate("/dashboard/admin");
            }
          }
        }}
        message={message}
        anchorOrigin={anchor}
        id={`base-${severity}-snackbar`}
        classes={{
          root: "base-snackbar",
        }}
      >
        <Alert
          onClose={() => {
            setOpen(false);
            if (severity === "success") {
              navigate("/dashboard/admin");
            }
          }}
          severity={severity}
          sx={{ width: "100%" }}
          variant="outlined"
          closeText="Close"
          classes={{
            root: "base-alert",
            outlinedError: "base-error-alert",
            outlinedInfo: "base-info-alert",
            outlinedSuccess: "base-success-alert",
            outlinedWarning: "base-warning-alert",
            icon: "base-alert-icon",
            message: "base-alert-message",
            action: "base-alert-action",
          }}
          slotProps={{
            closeButton: { className: "base-alert-close-btn" },
            closeIcon: { className: "base-alert-close-icon" },
          }}
        >
          {message}
        </Alert>
      </Snackbar>
    ),
    signInError: (
      <Snackbar
        open={open}
        onClose={() => {
          stateValues.manualLogin.reset();
          stateValues.setPassword(() => "");
          stateValues.enteredPassword.current.value = "";
          setOpen(false);
        }}
        message={message}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        id={`base-${severity}-snackbar`}
        classes={{
          root: "base-snackbar",
        }}
      >
        <Alert
          severity={severity}
          sx={{ width: "100%" }}
          variant="outlined"
          closeText="Close"
          classes={{
            root: "base-alert",
            outlinedError: "base-error-alert",
            outlinedInfo: "base-info-alert",
            outlinedSuccess: "base-success-alert",
            outlinedWarning: "base-warning-alert",
            icon: "base-alert-icon",
            message: "base-alert-message",
            action: "base-alert-action",
          }}
          slotProps={{
            closeButton: { className: "base-alert-close-btn" },
            closeIcon: { className: "base-alert-close-icon" },
          }}
        >
          {message}
        </Alert>
      </Snackbar>
    ),
    signInSuccess: (
      <Snackbar
        open={open}
        autoHideDuration={1200}
        onClose={async () => {
          if (stateValues.verified === false) {
            stateValues.setPassword(() => "");
            stateValues.enteredPassword.current.value = "";
            setOpen(false);
            return;
          }

          if (stateValues.adminUser && stateValues.isAdmin) {
            stateValues.navigate("/dashboard/admin", { replace: true });
          } else {
            stateValues.navigate(`/dashboard/client/${stateValues.clientId}`, {
              replace: true,
            });
          }
        }}
        message={message}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        id={`base-${severity}-snackbar`}
        classes={{
          root: "base-snackbar",
        }}
      >
        <Alert
          severity={severity}
          sx={{ width: "100%" }}
          variant="outlined"
          closeText="Close"
          classes={{
            root: "base-alert",
            outlinedError: "base-error-alert",
            outlinedInfo: "base-info-alert",
            outlinedSuccess: "base-success-alert",
            outlinedWarning: "base-warning-alert",
            icon: "base-alert-icon",
            message: "base-alert-message",
            action: "base-alert-action",
          }}
          slotProps={{
            closeButton: { className: "base-alert-close-btn" },
            closeIcon: { className: "base-alert-close-icon" },
          }}
        >
          {message}
        </Alert>
      </Snackbar>
    ),
    checkSubmit: (
      <Snackbar
        id="submit-check-snackbar"
        open={open}
        severity="warning"
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={stateValues.closeCheck}
        key={"submit-check"}
        classes={{
          root: "submit-check-snackbar",
        }}
      >
        <Alert
          id="submit-check-alert"
          severity="warning"
          classes={{
            root: "submit-check-alert",
            icon: "submit-check-alert-icon",
            message: "submit-check-alert-message",
            action: "submit-check-alert-action",
            outlinedWarning: "submit-check-alert-warning",
          }}
          action={
            <>
              <Button
                id="action-cancel-btn"
                color="inherit"
                size="small"
                variant="contained"
                onClick={stateValues.cancelSubmit}
              >
                Cancel
              </Button>
              <Button
                id="action-submit-btn"
                color="inherit"
                size="small"
                variant="contained"
                onClick={(e) => {
                  let verified = verifySubmission(stateValues.slideStatus);
                  console.log("SUBMIT STATUS: ", verified);
                  if (verified === true || stateValues.requestStatus === "RV") {
                    stateValues.invokeSubmitHandler(e);
                  } else {
                    stateValues.setOpenReq(true);
                  }
                }}
              >
                Submit
              </Button>
            </>
          }
        >
          {message}
        </Alert>
      </Snackbar>
    ),
    "formBtns-error": () => {
      return (
        <Snackbar
          id="error-snackbar"
          open={open}
          anchorOrigin={anchor}
          onClose={() => setOpen(false)}
          key={"required-fields-check"}
        >
          <Alert
            id="error-alert"
            severity="error"
            action={
              <>
                <Button
                  id="error-close-btn"
                  variant="contained"
                  color="inherit"
                  size="small"
                  onClick={() => setOpen(false)}
                >
                  Close
                </Button>
              </>
            }
          >
            {message}
          </Alert>
        </Snackbar>
      );
    },
    "success-submit": () => {
      const { setStatusMessage, isAdmin, clientId } = stateValues;
      return (
        <Snackbar
          id="submit-success-snackbar"
          open={open}
          anchorOrigin={anchor}
          onClose={() => {
            setStatusMessage("");
            if (isAdmin) {
              dispatch(setClientID(null));
              dispatch(setProjectID(null));
              dispatch(clearClient());
              navigate("../admin", { replace: true });
            } else {
              dispatch(setProjectID(null));
              navigate(`../client/${clientId}`, { replace: true });
            }
          }}
          autoHideDuration={3500}
          key={"submit-status-success"}
        >
          <Alert id="submit-success-alert" severity="success">
            {message}
          </Alert>
        </Snackbar>
      );
    },
    "submit-error": () => {
      const { setStatusMessage } = stateValues;
      return (
        <Snackbar
          id="submit-error-snackbar"
          open={open}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={() => setStatusMessage("")}
          key={"submit-status-error"}
        >
          <Alert
            id="submit-error-alert"
            severity="error"
            action={
              <>
                <Button
                  id="error-close-btn"
                  color="inherit"
                  size="small"
                  variant="contained"
                  onClick={() => setStatusMessage("")}
                >
                  Close
                </Button>
              </>
            }
          >
            {message}
          </Alert>
        </Snackbar>
      );
    },
  };
  return snackbarTypes[type];
};
