import { combineReducers } from "@reduxjs/toolkit";
import clientReducer from "./clientReducers";
import { userReducer } from "./userReducers";

// Combine all reducers into a single reducer
const reducer = combineReducers({
  user: userReducer,
  client: clientReducer,
});

export default reducer;
